<template>
  <SDialog :visible="dialogVisible" :show-close="true" @closed="onClose" @close="onClose" :showFooter="false" width="600px">
    <div class="title">
      <span class="row blue">
        {{ $t('report.rebate.others.instrumentsTraded') }}
      </span>
      <span>
        {{ $t('common.keys.FROM') }}
        <span class="blue">{{ $parent.startDate | formatDate('DD/MM/YYYY') }} </span>
        {{ $t('common.keys.TO') }}
        <span class="blue">{{ $parent.endDate | formatDate('DD/MM/YYYY') }}</span>
      </span>
    </div>
    <div class="info">
      <ul>
        <li>
          <span>{{ $t('report.rebate.column.name') }}</span>
          <p>{{ viewTradeDetail.name }}</p>
        </li>
        <li>
          <span>{{ $t('report.rebate.column.accountNumber') }}</span>
          <p>{{ viewTradeDetail.mt4Account }}</p>
        </li>
      </ul>
    </div>
    <!-- echarts图表 -->
    <div class="echarts">
      <div id="donut" style="height: 100%; width: 100%;"></div>
    </div>
    <!-- 表格 -->
    <div class="table-content">
      <el-collapse accordion>
        <el-collapse-item>
          <template slot="title">
            <div class="title">
              <p>
                <span>{{ $t('common.keys.tradingHistory') }}</span>
                <!-- <img src="@/assets/images/menu/down.png" alt="" /> -->
              </p>
            </div>
          </template>
          <div class="table-box">
            <el-table :data="detailData" height="100%" width="100%">
              <el-table-column :label="$t('report.rebate.column.date')" min-width="95">
                <template slot-scope="scope">
                  <span>{{ scope.row.closeDate | date('DD/MM/YYYY') }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="symbol"
                :label="$t('report.rebate.column.instrument')"
                min-width="120"
              ></el-table-column>
              <el-table-column :label="$t('report.rebate.column.volume').toUpperCase()" min-width="80">
                <template slot-scope="scope">
                  <span>{{ scope.row.volume | formatNumber }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('report.rebate.column.rebate').toUpperCase()" min-width="110">
                <template slot-scope="scope">
                  <span>{{ $parent.currAccountCurrency }}{{ scope.row.commission | currency('') }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </SDialog>
</template>

<script>
import { apiPayer_trade_details } from '@/resource';
export default {
  props: {
    dialogVisible: Boolean,
    viewTradeDetail: Object
  },
  data() {
    return {
      detailData: [],
      donutChart: null,
      donutOptions: {
        color: this.$config.chartColor,
        tooltip: {
          trigger: 'item',
          formatter: '{c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          type: 'scroll',
          left: '30',
          top: '0',
          data: [],
          textStyle: {
            color: 'rgb(98, 109, 113)'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['35%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'inside'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: []
          }
        ]
      },
      noDonutChartData: true
    };
  },
  watch: {
    viewTradeDetail(viewTradeDetail) {
      this.queryTradingDetail(viewTradeDetail.mt4Account);
    }
  },
  methods: {
    queryTradingDetail(mt4Account) {
      this.detailData = [];
      apiPayer_trade_details({
        mt4Account: mt4Account,
        agentAccount: this.$parent.accountID,
        startDate: this.$parent.startDate,
        endDate: this.$parent.endDate,
        symbol:
          Array.isArray(this.$parent.selectedInstrument) && this.$parent.selectedInstrument.length == 2
            ? this.$parent.selectedInstrument[1]
            : null
      })
        .then(response => {
          this.donutChart = this.echarts.init(document.getElementById('donut'));
          this.donutChart.setOption(this.donutOptions);
          this.detailData = response.data.tradeHistory || [];
          let donutData = Array.from(new Set(this.detailData.map(d => d.symbol))).map(symbol => {
            return {
              name: symbol,
              value: 0
            };
          });
          this.detailData.forEach(data => {
            donutData.find(d => d.name === data.symbol).value += data.volume;
          });
          donutData.forEach(d => {
            d.value = d.value.toFixed(2);
          });
          this.updateDonutChart(donutData);
        })
        .catch(err => {
          console.log(err);
          this.donutChart = this.echarts.init(document.getElementById('donut'));
          this.donutOptions.series[0].data = [];
          this.donutOptions.legend.data = [];
          this.donutChart.setOption(this.donutOptions);
        });
    },
    updateDonutChart(data) {
      this.noDonutChartData = false;
      this.donutChart.clear();

      if (data.length === 0) {
        this.donutOptions.series[0].data = [];
        this.donutOptions.legend.data = [];
        this.noDonutChartData = true;
      } else {
        this.donutOptions.series[0].data = data;
        this.donutOptions.legend.data = data.map(s => s.name);
        this.donutChart.setOption(this.donutOptions);
        this.noDonutChartData = false;
      }
    },
    onClose() {
      this.$emit('update:dialogVisible', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/rebateReport/dialog.scss';
</style>
