<template>
  <PageWrapper>
    <PagePanel>
      <PageSearchArea @queryData="queryRebateReport">
        <template #form>
          <el-form label-position="top">
            <AccountNumber @accountCallback="init"></AccountNumber>
            <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
            <el-form-item :label="$t('common.field.productType')">
              <el-cascader
                expand-trigger="hover"
                :options="instrumentOptions"
                v-model="selectedInstrument"
                :show-all-levels="false"
              ></el-cascader>
            </el-form-item>
          </el-form>
        </template>
      </PageSearchArea>
    </PagePanel>
    <PagePanel>
      <section class="center_card">
        <div class="center_card_title">
          <img src="@/assets/images-1/rebate_title_icon.png" class="title_icon" />
          <span class="title_text">{{ $t('report.rebate.others.totalRebate') }}:</span>
          <span class="curr_text">{{ currAccountCurrencyFilter }}</span>
          <span>{{ totalRebate | currency('') }}</span>
        </div>
        <el-row :gutter="16" class="card_list">
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4" v-for="(summary, index) in summaries" v-bind:key="index">
            <div class="card_list_item">
              <div class="item_title">
                <img src="@/assets/images-1/rebate_card_icon.png" class="title_icon"/>
                <span class="title_text">{{ summary.category }}</span>
              </div>
              <div class="item_content">
                <div class="content_label">{{ $t('report.rebate.others.lots') }}</div>
                <div class="content_value">{{ summary.volume | formatNumber }}</div>
              </div>
              <div class="item_content">
                <div class="content_label">{{ $t('report.rebate.others.rebate') }}</div>
                <div class="content_value">
                  <span class="curr_text">{{ currAccountCurrencyFilter }}</span>
                  <span>{{ summary.commission | currency('') }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </section>
    </PagePanel>

    <PagePanel class="table_box">
      <h3 class="table_title">{{$t('common.field.commissionList')}}</h3>
      <!-- 表格部分 -->
      <el-table
        :data="displayData"
        ref="summaryTable"
        style="width: 100%;"
        :default-sort="{ prop: 'totalrebate', order: 'descending' }"
        @sort-change="sortChange"
      >
        <el-table-column :label="$t('report.rebate.column.name')" style="word-wrap: normal;" min-width="100">
          <template slot-scope="scope">
            <a
              @click.stop.prevent="
                dialogVisible = true;
                viewTradeDetail = { name: scope.row.name, mt4Account: scope.row.number };
              "
              data-testid="name"
              >{{ scope.row.name }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="number"
          :label="$t('report.rebate.column.accountNumber')"
          min-width="120"
        ></el-table-column>
        <el-table-column :label="$t('report.rebate.column.accountType')" min-width="70">
          <template slot-scope="scope">
            {{ $config.accountTypeMaps[scope.row.type] }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('common.products.forex')"
          sortable
          sort-by="forex.rebate"
          :sort-orders="['ascending', 'descending']"
        >
          <el-table-column :label="$t('report.rebate.column.volume')">
            <template slot-scope="scope">
              <span>{{ scope.row.forex.volume | formatNumber }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('report.rebate.column.rebate')">
            <template slot-scope="scope">
              <span>{{ currAccountCurrencyFilter }}{{ scope.row.forex.rebate | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('common.products.commodities')"
          sortable
          sort-by="commodities.rebate"
          :sort-orders="['ascending', 'descending']"
        >
          <el-table-column :label="$t('report.rebate.column.volume')">
            <template slot-scope="scope">
              <span>{{ scope.row.commodities.volume | formatNumber }}</span>
              <p style="border-right: 1px solid red;"></p>
            </template>
          </el-table-column>

          <el-table-column :label="$t('report.rebate.column.rebate')">
            <template slot-scope="scope">
              <span>{{ currAccountCurrencyFilter }}{{ scope.row.commodities.rebate | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('common.products.indices')"
          sortable
          sort-by="indices.rebate"
          :sort-orders="['ascending', 'descending']"
        >
          <el-table-column :label="$t('report.rebate.column.volume')">
            <template slot-scope="scope">
              <span>{{ scope.row.indices.volume | formatNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('report.rebate.column.rebate')">
            <template slot-scope="scope">
              <span>{{ currAccountCurrencyFilter }}{{ scope.row.indices.rebate | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('common.products.crypto')"
          sortable
          sort-by="crypto.rebate"
          :sort-orders="['ascending', 'descending']"
        >
          <el-table-column :label="$t('report.rebate.column.volume')">
            <template slot-scope="scope">
              <span>{{ scope.row.crypto.volume | formatNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('report.rebate.column.rebate')">
            <template slot-scope="scope">
              <span>{{ currAccountCurrencyFilter }}{{ scope.row.crypto.rebate | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('common.products.sharecfd')"
          sortable
          sort-by="sharecfd.rebate"
          :sort-orders="['ascending', 'descending']"
        >
          <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.sharecfd.volume | formatNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
            <template slot-scope="scope">
              <span>{{ currAccountCurrencyFilter }}{{ scope.row.sharecfd.rebate | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('common.products.bond')"
          sortable
          sort-by="bond.rebate"
          :sort-orders="['ascending', 'descending']"
        >
          <el-table-column :label="$t('report.rebate.column.volume')" min-width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.bond.volume | formatNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('report.rebate.column.rebate')" min-width="70">
            <template slot-scope="scope">
              <span>{{ currAccountCurrencyFilter }}{{ scope.row.bond.rebate | currency('') }}</span>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('report.rebate.column.totalRebate')"
          sortable
          sort-by="totalrebate"
          :sort-orders="['ascending', 'descending']"
          min-width="110"
        >
          <template slot-scope="scope">
            <span>{{ currAccountCurrencyFilter }}{{ scope.row.totalrebate | currency('') }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <FakePagination :raw-data="tableData" :display-data.sync="displayData"></FakePagination>
    </PagePanel>
    <Dialog :dialogVisible.sync="dialogVisible" :viewTradeDetail="viewTradeDetail"></Dialog>
  </PageWrapper>
</template>

<script>
import DateRangePicker from '@/components/customUI/DateRangePicker';
import Dialog from '@/components/rebateReport/Dialog';
import AccountNumber from '@/components/customUI/AccountNumber';
import FakePagination from '@/components/elementUI/Pagination/FakePagination';
import { apiRebate_report, apiGet_instruments } from '@/resource';

export default {
  components: { DateRangePicker, Dialog, AccountNumber, FakePagination },
  data() {
    return {
      startDate: '',
      endDate: '',
      userID: this.$store.state.common.CUID,
      dialogVisible: false,
      totalRebate: 0,
      summaries: [
        {
          category: this.$t('common.products.forex'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.commodities'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.indices'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.crypto'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.sharecfd'),
          commission: 0,
          volume: 0
        },
        {
          category: this.$t('common.products.bond'),
          commission: 0,
          volume: 0
        }
      ],
      viewTradeDetail: { name: null, mt4Account: null },
      selectedInstrument: [null],
      instrumentOptions: [
        {
          value: null,
          label: this.$t('report.rebate.dropdown.allInstruments')
        },
        {
          value: 'forex',
          label: this.$t('common.products.forex'),
          children: []
        },
        {
          value: 'commodities',
          label: this.$t('common.products.commodities'),
          children: []
        },
        {
          value: 'indices',
          label: this.$t('common.products.indices'),
          children: []
        },
        {
          value: 'crypto',
          label: this.$t('common.products.crypto'),
          children: []
        },
        {
          value: 'sharecfd',
          label: this.$t('common.products.sharecfd'),
          children: []
        },
        {
          value: 'bond',
          label: this.$t('common.products.bond'),
          children: []
        }
      ],
      // 分页
      displayData: [],
      sorting: { column: 'totalrebate', order: 'descending' },
      tableData: [],
      currAccountCurrencyFilter: ''
    };
  },
  watch: {
    dataSourceId() {
      this.queryInstruments();
    }
  },
  methods: {
    queryRebateReport() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }

      return apiRebate_report({
        account: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        symbol:
          Array.isArray(this.selectedInstrument) && this.selectedInstrument.length == 2
            ? this.selectedInstrument[1]
            : null
      }).then(response => {
        this.totalRebate = response.data.total;

        let keyMap = { 'share cfd': 'sharecfd' };
        this.tableData = response.data.payerSummaries.map(item => {
          var objs = Object.keys(item).reduce((newData, key) => {
            let newKey = keyMap[key] || key;
            newData[newKey] = item[key];
            return newData;
          }, {});
          return objs;
        });

        this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);

        this.sortData();
        this.summaries.forEach(s => {
          s.commission = s.volume = 0;
          for (let key in response.data.summaries) {
            let temp = key.toLowerCase().replace(/\s+/g, '');
            temp = this.$t('common.products.' + temp);
            if (temp === s.category) {
              s.commission = response.data.summaries[key].commission;
              s.volume = response.data.summaries[key].volume;
              break;
            }
          }
        });
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'totalrebate';
      this.sorting.order = this.sorting.order || 'descending';
      this.tableData.sort((a1, a2) => {
        if (this.sorting.column == 'totalrebate') {
          return this.sorting.order == 'descending'
            ? a2['totalrebate'] - a1['totalrebate']
            : a1['totalrebate'] - a2['totalrebate'];
        }
        if (this.sorting.column == 'forex.rebate') {
          return this.sorting.order == 'descending'
            ? a2['forex']['rebate'] - a1['forex']['rebate']
            : a1['forex']['rebate'] - a2['forex']['rebate'];
        }
        if (this.sorting.column == 'commodities.rebate') {
          return this.sorting.order == 'descending'
            ? a2['commodities']['rebate'] - a1['commodities']['rebate']
            : a1['commodities']['rebate'] - a2['commodities']['rebate'];
        }
        if (this.sorting.column == 'indicies.rebate') {
          return this.sorting.order == 'descending'
            ? a2['indicies']['rebate'] - a1['indicies']['rebate']
            : a1['indicies']['rebate'] - a2['indicies']['rebate'];
        }
        if (this.sorting.column == 'crypto.rebate') {
          return this.sorting.order == 'descending'
            ? a2['crypto']['rebate'] - a1['crypto']['rebate']
            : a1['crypto']['rebate'] - a2['crypto']['rebate'];
        }
        if (this.sorting.column == 'sharecfd.rebate') {
          return this.sorting.order == 'descending'
            ? a2['sharecfd']['rebate'] - a1['sharecfd']['rebate']
            : a1['sharecfd']['rebate'] - a2['sharecfd']['rebate'];
        }
        if (this.sorting.column == 'bond.rebate') {
          return this.sorting.order == 'descending'
            ? a2['bond']['rebate'] - a1['bond']['rebate']
            : a1['bond']['rebate'] - a2['bond']['rebate'];
        }
      });
    },
    queryInstruments() {
      if (!this.dataSourceId) {
        return;
      }

      //恢復預設
      this.instrumentOptions.forEach(i => {
        if (Array.isArray(i.children)) i.children = [];
      });

      apiGet_instruments({ dataSourceId: this.dataSourceId }).then(response => {
        for (let k in response.data) {
          let instrument = this.instrumentOptions.find(i => i.value == k.replace(/\s+/g, ''));
          if (instrument && Array.isArray(instrument.children)) {
            instrument.children = response.data[k].sort().map(prod => {
              return { value: prod, label: prod };
            });
          }
        }
      }, this.$handleError);
    },
    sortChange(column) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
      console.log(this.displayData);
    },
    init() {
      this.queryInstruments();
      this.queryRebateReport();
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    dataSourceId() {
      return this.$store.state.behavior.dataSourceId;
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/rebateReport.scss';
.center_card {
  .center_card_title {
    display: flex;
    align-items: center;
    color: $primary;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    .title_icon {
      width: 24px;
    }
    .title_text {
      font-size: 16px;
      color: $text-primary;
      padding: 0 8px;
    }
    .curr_text {
      @include rtl-sass-prop(padding-right, padding-left, 5px);
    }
  }
  .card_list {
    margin-bottom: -16px;
    .card_list_item {
      padding: 16px;
      border-radius: 8px;
      background-color: $background-color-base;
      margin-bottom: 16px;
      .item_title {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .title_icon {
          width: 24px;
          @include rtl-sass-prop(margin-right, margin-left, 8px);
        }
        .title_text {
          font-size: 14px;
          color: $text-primary;
          text-transform: uppercase;
        }
      }
      .item_content {
        margin-bottom: 16px;
        .content_label {
          color: #6e6e73;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 8px;
        }
        .content_value {
          font-size: 14px;
          line-height: 22px;
          color: $text-primary;
          font-weight: 700;
          .curr_text {
            @include rtl-sass-prop(padding-right, padding-left, 5px);
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.table_box {
  .table_title {
    font-size: 16px;
    font-weight: 700;
    color: $text-primary;
    margin-bottom: 16px;
  }
}
</style>
